import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import UserContextProvider from './contexts/userContext';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
	<Router>
		<UserContextProvider>
			<App />
		</UserContextProvider>
	</Router>,
	document.getElementById('root'),
);
