import styled, { css } from 'styled-components';
import { ReactComponent as Arrow } from '../assets/images/arrow.svg';

export const Tabs = styled.div`
	display:flex;
	justify-content:space-between;
	background-color:var(--c-accent-200);
	width:fit-content;
	border-radius:2em;
	box-shadow: inset 0 0 8px rgba(0,0,0,0.08);
	position:relative;
	// overflow:hidden;
}`;

export const SingleTab = styled.div`
	font-size:var(--fz-accent);
	color:var(--c-main-700);
	height:100%;
	padding:0.3em 2em;
	cursor:pointer;
	z-index:1;
	width:max-content;
	&:first-of-type, &:last-of-type{
		padding-inline:2em !important;
	}
}`;

export const TabIndicator = styled.div`
	position:absolute;
	background-color:var(--c-accent-300);
	box-shadow:var(--box-shadow-300);
	right:0;
	width:${(props) => props.width + '%'};
	height:100%;
	border-radius:2em;
	transform:${(props) => props.transform};
	transition: transform 0.25s ease;


}`;

export const StandardBtn = styled.button`
	min-width:185px;
	max-width:205px;
	color:var(--c-main-700);
	font-size:var(--fz-accent);
	font-weight:700;
	gap:0.5em;
	display:flex;
	align-items:center;
	justify-content:center;
	min-height:50px;
	background:var(--c-neutral-100);
	border:none;
	border-radius:2em;
	width:50%;
	cursor:pointer;
	svg{
		align-self: center !important;
		margin-inline-start:unset !important;
	}
	${({ background }) =>
		background &&
		css`
		background:${background};
	}`}
	${({ border }) =>
		border &&
		css`
		border: 1px solid var(--c-main-100);
	}`}
	${({ width }) =>
		width &&
		css`
		max-width: unset !important;
		width:max-content !important;
		span{
			max-width:100%;
			padding-inline:2vmin;
		}
	}`}

	${({ shadow }) =>
		shadow &&
		css`
		box-shadow:${shadow};
	}`}
	${({ position }) =>
		position &&
		css`
		margin-inline-start:auto;
		width:15vmin;
		min-width:unset;
	}`}
	${({ small }) =>
		small &&
		css`
		min-height:unset;
		min-width: unset;
		width: 39px;
		aspect-ratio:1;
		position:absolute;
		left: -5%;
		top:50%;
		transform: translate(-100%, -50%);
	}`}
	&:disabled{
		opacity:0.8;
		filter:grayscale(0.5)
	}
	@media only screen and (max-width:560px) {
	min-width:150px;
	max-width:unset;
	  }
}`;

export const CustomInputDiv = styled.div`
	border-radius: var(--border-radius);
	border: 1px solid var(--c-main-100);
	background-color: var(--c-main-100);
	padding: 0.3vmin 1vmin;
	overflow: hidden;
	max-height: 50px;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;

	&:focus-within {
		outline: none;
		border: 1px solid var(--c-main-500);
	}
`;

export const DropList = styled.ul`
	list-style:none;
	padding:unset;
	position:absolute;
	z-index:11;
	width:max-content;
	right:0;
	bottom:-15%;
	background-color: var(--c-main-100);
	border-radius:4px;
	min-width:40%;
	max-width:35vw;
	${({ dept }) =>
		!dept &&
		css`
		margin-inline-start: 1vmin;
		transform:translateY(100%);
	}`}
	${({ dept }) =>
		dept &&
		css`
		bottom:unset;
		right:unset;
		top:0;	
		left:-5%;
		transform:translateX(-100%);
	}`}
	
}`;
export const DropContainer = styled.div`
	position:relative;
	max-width:100%;
	font-size:var(--fz-regular);
	position:relative;
}`;
export const FilterSelect = styled.div`
position:relative;
	max-width:250px;
	width:100%;
	height:50px;
	background-color:transparent;
	font-size:var(--fz-accent);
	color:var(--c-main-700);
	border-radius:var(--border-radius);
	border:1px solid var(--c-main-700);
	padding:0.2em 0.8em;
	display:flex;
	align-items:center;
	justify-content:space-between;
	position:relative;
	cursor:pointer;

	&:focus{
		outline:none;
		border:2px solid var(--c-main-700);
	}
	ul{
		padding:unset;
		padding: 0.2em 0.8em;
		position:absolute;
		bottom:0;
		transform:translate(7.5%,100%);
		z-index:11;
		list-style:none;
		width:100%;
		background-color: var(--c-neutral-200);
		// box-shadow: var(--box-shadow-200);
		border-radius:var(--border-radius);
		border:1px solid var(--c-main-700);

		li{
			margin-bottom:1vmin;
			cursor:pointer;
			&.active{
				font-weight:650;
			}
		}
	}
	
	
}`;
export const DropItem = styled.li`
	min-height: 3.5em;
	padding-inline:2.5vmin;
	width:100%;
	border-bottom:1px solid var(--c-main-400);
	cursor:pointer;
	display:flex;
	align-items:center;
	//justify-content:space-between;
	
	&:last-of-type{
	border-bottom:unset;
	}

	${({ active }) =>
		active &&
		css`
		background-color:var(--c-accent-300);
	}`}
	span{
		font-weight:700;
		color:var(--c-main-700);
	}
	label{
		cursor:pointer;
		font-weight:400;
		color:var(--c-main-700);
		font-size:var(--fz-accent);
		padding:2vmin;
	}

	svg{
		margin-inline-start:auto;
		height:0.75em;
	}
}`;

export const ActionSmall = styled.button`
	display:flex;
	align-items:center;
	background:transparent;
	border:unset;
	cursor:pointer;
	width:max-content;
	svg,path{
		fill:var(--c-main-600);
	}

	&:hover{
	svg,path{
		 fill:var(--c-main-700);
	}
	}
}`;

export const SubBtn = styled.button`
	display:flex;
	align-items:center;
	justify-content:space-evenly;
	background:transparent;
	box-shadow:var(--box-shadow-400);
	border:unset;
	border-radius:var(--border-radius);
	cursor:pointer;
	font-size:1.2em;
	transition:all 0.2s ease;
	padding:1vmin 1vmin 0.5vmin;
	gap:0.5vmin;
	min-width:30%;

	&:hover{
	background:var(--c-accent-100);
	}

	svg{
		fill:var(--c-main-500);
		margin-inline-start: unset !important;
		align-self:center;
	}
}`;
