import { useContext } from 'react';
import { ReactComponent as Exit } from '../../assets/images/exit.svg';
import { DataContext } from '../../contexts/dataContext';
import { WarningModal } from '../../styles/general';
import Button from '../buttons/Button';

const SubStatusWarning = ({
	mode,
	currSub,
	cancelHandler,
	continueHandler,
}) => {
	const dataContext = useContext(DataContext);

	return (
		<WarningModal style={{ maxWidth: 'min-content', gap: '1vmin' }}>
			<span>
				הנך עומד לבצע פעולת {mode} על מנוי מספר {currSub?.subNumber}.
				אתה בטוח שברצונך להמשיך?
			</span>

			<span
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					maxWidth:
						mode === 'ביטול' &&
						dataContext.selected.payment === 'שולם'
							? '100%'
							: '85%',
					width: '100%',
					flexDirection:
						mode === 'ביטול' &&
						dataContext.selected.payment === 'שולם'
							? 'column'
							: 'unset',
					gap:
						mode === 'ביטול' &&
						dataContext.selected.payment === 'שולם'
							? '1vmin'
							: 'unset',
					alignItems:
						mode === 'ביטול' &&
						dataContext.selected.payment === 'שולם'
							? 'center'
							: 'unset',
				}}
			>
				<Button
					clickHandler={cancelHandler}
					border={true}
					shadow='var(--box-shadow-100)'
					position={true}
				>
					<Exit />
					<span>ביטול</span>
				</Button>
				{mode === 'ביטול' && dataContext.selected.payment === 'שולם' ? (
					<>
						<Button
							clickHandler={() => {
								continueHandler('charge agent');
							}}
							border={true}
							shadow='var(--box-shadow-100)'
							background='linear-gradient(180deg, rgba(39,160,231,0.88) 54%, rgba(39,160,231,0.54) 100%)'
							position={true}
							width={true}
						>
							<span>המשך עם חיוב סוכן</span>
						</Button>
						<Button
							clickHandler={() => {
								continueHandler('charge client');
							}}
							border={true}
							shadow='var(--box-shadow-100)'
							background='linear-gradient(180deg, rgba(39,160,231,0.88) 54%, rgba(39,160,231,0.54) 100%)'
							position={true}
							width={true}
						>
							<span>המשך ללא חיוב סוכן</span>
						</Button>
					</>
				) : (
					<Button
						clickHandler={() => {
							continueHandler('no payment');
						}}
						border={true}
						shadow='var(--box-shadow-100)'
						background='linear-gradient(180deg, rgba(39,160,231,0.88) 54%, rgba(39,160,231,0.54) 100%)'
						position={true}
					>
						<span>המשך</span>
					</Button>
				)}
			</span>
			{mode === 'ביטול' &&
				dataContext.selected.payment === 'ממתין לתשלום' && (
					<p>
						עם לחיצה על המשך תחויב במחיר היחסי לפי עלות המנוי ומשך
						הזמן בו היה פעיל.
					</p>
				)}
		</WarningModal>
	);
};

export default SubStatusWarning;
