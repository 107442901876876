import { useContext } from 'react';
import { Navigate } from 'react-router';
import Layout from '../components/Layout';
import { UserContext } from '../contexts/userContext';

const PrivateRoute = ({ children }) => {
	const userContext = useContext(UserContext);

	return userContext.auth ? (
		<Layout>{children}</Layout>
	) : (
		<Navigate to='/login' replace />
	);
};

export default PrivateRoute;
