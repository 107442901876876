import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { ReactComponent as Delete } from '../../../assets/images/delete.svg';
import { ReactComponent as Exit } from '../../../assets/images/exit.svg';
import { ActionsBarContext } from '../../../contexts/actionsBarContext';
import { AlertContext } from '../../../contexts/alertContext';
import { DataContext } from '../../../contexts/dataContext';
import { UserContext } from '../../../contexts/userContext';
import { Actions, Cancel, Status, TitleCard } from '../../../styles/actions';
import {
	addSubscription,
	cancelPayment,
	editSubDetails,
	editSubStatus,
	getPaymentBySubId,
	getRelatedPayments,
	newCommissionListing,
	newPaymentListing,
} from '../../../utils/api';
import SubStatusWarning from '../../alerts/SubStatusWarning';
import Button from '../../buttons/Button';
import SubButtons from '../../buttons/SubButtons';
import ReportsBox from '../../Containers/ReportsBox';
import ReportAgent from '../../reports/ReportAgent';
import ReportClient from '../../reports/ReportClient';

const ActionsBar = () => {
	const context = useContext(ActionsBarContext);
	const userContext = useContext(UserContext);
	const dataContext = useContext(DataContext);
	const alertContext = useContext(AlertContext);
	const [showWarning, setShowWarning] = useState(false);
	const [mode, setMode] = useState('');
	const [actions, setActions] = useState(true);
	const [paymentId, setPaymentId] = useState();
	const [modal, setModal] = useState(false);
	const [leftToPay, setLeftToPay] = useState(0);
	const location = useLocation();
	const navigate = useNavigate();
	// const [totalCom, setTotalCom] = useState(0);

	const handleRenewSub = async () => {
		let prevDateEnd = new Date(dataContext.selected.dateEnd);
		let newDateStart = prevDateEnd.setDate(prevDateEnd.getDate() + 1);
		let newDateEnd = new Date(newDateStart).setDate(
			new Date(newDateStart).getDate() + 364,
		);

		// return console.log(newDateStart, newDateEnd);
		//change start date to now,
		let renewedSub = {
			// id: dataContext.selected.id,
			...dataContext.selected,
			id: null,
			status: 'פעיל',
			dateStart: newDateStart,
			dateEnd: newDateEnd,
			subNumber: +dataContext?.selected?.subNumber + 1,
			agentCom: 0,
		};

		let result = await addSubscription(renewedSub);
		if (!result.success)
			return alertContext.handleAlert(false, result.message);

		dataContext.setReloadMode(true);
		// dataContext.setSwapMode(true);
		//create another payment listing for selected
		setShowWarning(false);
		context.handleState(false);
		navigate('/subscriptions', {
			state: { showData: false, selected: result?.data?.id },
		});
	};

	const handleCancelSub = async (status) => {
		let dataToUpdate = {
			id: dataContext.selected.id,
			dateEnd: new Date(),
			status: 'מבוטל',
			payment: 'ממתין לתשלום',
		};

		let cancelRes = await editSubDetails(dataToUpdate);

		let refund = await calculateRefund(
			dataToUpdate.dateEnd,
			new Date(dataContext.selected.dateStart),
			status,
		);

		let comData = {
			agentId: dataContext.selected.agentId,
			action: 'ביטול מנוי',
			total: refund.agentCharge,
		};

		if (!refund.agentCharge) {
			// status charge client
			await newPaymentListing({
				subscriptionId: dataToUpdate.id,
				total: refund.clientRefund,
				date: dataToUpdate.dateEnd,
				status: 'זיכוי',
			});
			handleCloseModal(cancelRes);
		} else if (!refund.clientRefund) {
			// status no payment
			//need to find payment listing for sub id and cancel it and commission related to it
			let canceledPayment = await cancelPayment(
				dataContext?.selected?.id,
			);

			comData.comment = 'מנוי לא שולם עדיין';
			comData.paymentId = canceledPayment?.data?.id;
		} else {
			// status charge agent
			let newPayment = await newPaymentListing({
				subscriptionId: dataToUpdate.id,
				total: refund.clientRefund,
				date: dataToUpdate.dateEnd,
				status: 'זיכוי',
			});

			comData.comment = 'מנוי שולם - חיוב סוכן';
			comData.paymentId = newPayment?.data?.id;
		}

		//add request to add new commission listing
		await newCommissionListing(comData);

		handleCloseModal(cancelRes);
		if (!refund.clientRefund) return;
	};

	const handleSwapSub = () => {
		setShowWarning(false);
		context.handleState(false);
		dataContext.setSwapMode(true);
		navigate('/subscriptions', {
			state: { showData: false },
		});
	};

	const handleFreezeSub = async () => {
		let updateRes = await editStatusHandler('קפוא');
		handleCloseModal(updateRes);
	};

	const handleUnFreezeSub = async () => {
		let dateFrozen = new Date(dataContext.selected.updatedAt);
		let today = new Date();
		let prevDateEnd = new Date(dataContext.selected.dateEnd);
		let newDateEnd = new Date().setDate(
			prevDateEnd.getDate() + (today.getDate() - dateFrozen.getDate()),
		);

		let updateRes = await editStatusHandler('פעיל');
		if (updateRes.success) {
			await editSubDetails({
				id: dataContext.selected.id,
				dateEnd: newDateEnd,
			});
		}

		handleCloseModal(updateRes);
	};

	const editStatusHandler = async (status) => {
		dataContext.setReloadMode(true);
		return await editSubStatus({
			status,
			id: dataContext?.selected?.id,
		});
	};

	const calculateRefund = async (endDate, startDate, status) => {
		let rateDays = (endDate - startDate) / 1000 / 60 / 60 / 24;
		rateDays = Math.ceil(rateDays + 1);

		let priceWOCom = dataContext.selected.relativePrice;

		let priceWCom = dataContext.selected.subPrice;

		let relPriceWOCom = (priceWOCom / 365) * rateDays;

		let relPriceWCom = (priceWCom / 365) * rateDays;

		// status charge agent
		if (status === 'charge agent') {
			return {
				clientRefund: -Math.ceil(priceWCom - relPriceWCom),
				agentCharge: -Math.ceil(relPriceWOCom),
			};
		}
		// status charge client
		if (status === 'charge client') {
			return {
				clientRefund: -Math.ceil(
					priceWCom - relPriceWCom - relPriceWOCom,
				),
				agentCharge: 0,
			};
		}
		// status no payment
		if (status === 'no payment') {
			return {
				clientRefund: 0,
				agentCharge: -Math.ceil(relPriceWOCom),
			};
		}
	};

	const handleCloseModal = (updateRes) => {
		setShowWarning(false);
		alertContext.handleAlert(updateRes.success, updateRes.message);
		// dataContext.setReloadMode(false);
	};

	const returnContinueHandler = (status) => {
		switch (mode) {
			case 'ביטול':
				handleCancelSub(status);
				break;
			case 'החלפה':
				handleSwapSub();
				break;
			case 'חידוש':
				handleRenewSub();
				break;
			case 'הקפאה':
				handleFreezeSub();
				break;
			case 'הפשרה':
				handleUnFreezeSub();
				break;

			default:
				break;
		}
	};

	const getPaymentId = async () => {
		let payment = await getPaymentBySubId(dataContext.selected?.id);
		setPaymentId(payment?.data?.id);
	};

	const getPaymentRemainder = async () => {
		if (dataContext.selected?.total < dataContext.selected?.paymentSum)
			return;
		let paymentsBySub = dataContext.dataToShow.filter(
			(payment) =>
				payment.subscriptionId === dataContext.selected?.subscriptionId,
		);

		let sum = paymentsBySub.reduce((accumulator, curValue) => {
			return accumulator + curValue.paymentSum;
		}, 0);
		if (sum >= dataContext.selected?.total) return setLeftToPay(0);
		setLeftToPay(dataContext.selected?.total - sum);
	};

	const updateSelected = () => {
		let updatedSelected = dataContext.dataToShow.find(
			(item) => item.id === dataContext.selected.id,
		);
		if (!updatedSelected) return;
		dataContext.setSelected(updatedSelected);
		dataContext.setReloadMode(false);
	};

	useEffect(() => {
		if (dataContext.selected?.payment) getPaymentId();
	}, [dataContext.selected]);

	useEffect(() => {
		if (location.pathname === '/payments') getPaymentRemainder();
	}, [location.pathname, dataContext.selected]);

	useEffect(() => {
		if (!dataContext.selected?.id) return;
		updateSelected();
	}, [dataContext.dataToShow]);

	return (
		<>
			<Actions className={context.open && 'open-actions'}>
				<div className='inner'>
					<Exit
						onClick={() => {
							context.handleState(false);
							setActions(true);
						}}
					/>
					<TitleCard>
						{dataContext.selected
							? location.pathname === '/subscriptions' ||
							  location.pathname === '/payments' ||
							  location.pathname === '/claims'
								? dataContext.selected.clientName
								: location.pathname === '/commissions'
								? dataContext.selected?.agent?.firstName +
								  ' ' +
								  dataContext.selected?.agent?.lastName
								: dataContext.selected?.firstName +
								  ' ' +
								  dataContext.selected.lastName
							: 'כל הסוכנים'}
					</TitleCard>
					{(location.pathname === '/' ||
						(location.pathname === '/subscriptions' &&
							dataContext.selected?.firstName) ||
						dataContext.selected?.clientName) && (
						<Status
							className={
								dataContext.selected?.status === 'פעיל' ||
								dataContext.selected?.status === 'שולם'
									? 'active'
									: null
							}
						>
							<div>סטטוס:</div>
							<span>{dataContext.selected?.status}</span>
						</Status>
					)}
					{location.pathname === '/subscriptions' && (
						<SubButtons
							setShowWarning={setShowWarning}
							setMode={setMode}
						></SubButtons>
					)}

					{(location.pathname == '/agents' ||
						location.pathname == '/subscriptions' ||
						location.pathname == '/') && (
						<ReportsBox setModal={setModal}></ReportsBox>
					)}
					{dataContext.selected?.payment &&
						dataContext.selected?.payment === 'ממתין לתשלום' && (
							<Button
								clickHandler={async () => {
									navigate('/payments', {
										state: {
											paymentId,
											showData: false,
										},
									});
								}}
								shadow='var(--box-shadow-400)'
							>
								<span>מעבר לתשלום</span>
							</Button>
						)}
					<div className='action-btns'>
						{dataContext.selected && (
							<Button
								clickHandler={() => {
									dataContext.setUpdateMode(true);
									if (
										location.pathname === '/payments' &&
										dataContext.selected.status ===
											'תשלום חלקי'
									) {
										navigate('/payments', {
											state: {
												partial: 1,
												leftToPay,
											},
										});
									}
								}}
								shadow='var(--box-shadow-400)'
								background='var(--c-neutral-300)'
							>
								<span>
									{location.pathname === '/payments' &&
									dataContext.selected.status !== 'שולם'
										? 'תשלום'
										: 'עריכה'}
								</span>
							</Button>
						)}
						{userContext.user.permission === 10 && (
							<Button
								clickHandler={() => {
									dataContext.setDeleteModeHandler(true);
								}}
								shadow='var(--box-shadow-400)'
							>
								<span>מחיקה</span>
								<Delete />
							</Button>
						)}
					</div>
				</div>

				<Cancel>
					<Button
						clickHandler={() => {
							context.handleState(false);
							setActions(true);
						}}
						shadow='var(--box-shadow-100)'
						border={true}
					>
						<span>ביטול</span>
						<Exit />
					</Button>
				</Cancel>
			</Actions>

			{showWarning && (
				<SubStatusWarning
					currSub={dataContext.selected}
					mode={mode}
					cancelHandler={() => {
						setShowWarning(false);
					}}
					continueHandler={returnContinueHandler}
				/>
			)}

			{modal === 'agent' ? (
				<ReportAgent setModal={setModal} />
			) : modal === 'client' ? (
				<ReportClient setModal={setModal} />
			) : null}
		</>
	);
};

export default ActionsBar;
