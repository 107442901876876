import { useContext, useEffect, useState } from 'react';
import logo from '../../assets/images/logo@2x.png';
import { DataContext } from '../../contexts/dataContext';
import { ReportsModal } from '../../styles/general';
import { getRelatedPayments } from '../../utils/api';
import useBackButtonControl from '../../utils/useBackButtonControl';

const ReportAgent = ({ setModal }) => {
	const dataContext = useContext(DataContext);
	const [paymentData, setPaymentData] = useState([]);

	const handleBackButton = () => {
		setModal(false);
	};

	const getPaymentData = async () => {
		const res = await getRelatedPayments(dataContext.selected?.subNumber);

		setPaymentData(res.data);
	};

	const renderPaymentTable = () => {
		const paymentMethods = paymentData.reduce((result, element) => {
			if (!result.includes(element.paymentMethodId))
				result.push(element.paymentMethodId);
			return result;
		}, []);

		return paymentMethods.map((method) => {
			const data = paymentData.filter(
				(data) => data.paymentMethodId === method,
			);
			const checks =
				method !== 3 ? [] : [].concat(...data.map((d) => d.checks));

			return (
				<>
					<div className='title'>
						שולם ב
						{method === 1
							? 'מזומן'
							: method === 2
							? 'אשראי'
							: 'המחאות'}
					</div>

					{method === 1 ? (
						data.map((item) => (
							<div className='row-table'>
								<div className='cell'>סכום</div>
								<div className='cell'>{item?.cash?.total}</div>
							</div>
						))
					) : method === 2 ? (
						data.map((item, index) => (
							<div className='row-table' key={index}>
								<div className='cell'>סכום</div>
								<div className='cell'>
									{item?.creditCard?.total}
								</div>
								<div className='cell'>4 ספרות אחרונות</div>
								<div className='cell'>
									{item?.creditCard?.lastDigits}
								</div>
							</div>
						))
					) : (
						<div className='row-table'>
							<div className='cell'>
								<span>מספר המחאה</span>
								{checks.map((check) => (
									<span>{check?.number}</span>
								))}
							</div>
							<div className='cell'>
								<span>בנק</span>
								{checks.map((check) => (
									<span>{check?.bankName}</span>
								))}
							</div>
							<div className='cell'>
								<span>סניף</span>
								{checks.map((check) => (
									<span>{check?.branch}</span>
								))}
							</div>
							<div className='cell'>
								<span>חשבון</span>
								{checks.map((check) => (
									<span>{check?.accountNumber}</span>
								))}
							</div>
							<div className='cell'>
								<span>סכום</span>
								{checks.map((check) => (
									<span>{check?.total}</span>
								))}
							</div>
						</div>
					)}
				</>
			);
		});
	};

	useEffect(() => {
		getPaymentData();
	}, []);
	useEffect(() => {
		renderPaymentTable();
	}, [paymentData]);

	useBackButtonControl(handleBackButton);
	return (
		<ReportsModal>
			<div className='brand-heading'>
				<div className='image'>
					<img src={logo} alt='' />
				</div>
				<div className='text'>
					<p>ר.א צ'ק אין בע"מ</p>
					<p>טלפון: 04-9522823 פקס: 076-5100392</p>
					<p>ת.ד: 3139 חשמונאים 2 נהריה</p>
					<p>מייל: R.A.Chakin@Bezeqint.Net</p>
				</div>
			</div>

			<div className='table'>
				<div className='row-table'>
					<div className='cell'>
						{dataContext.selected?.subNumber}
					</div>
					<div className='cell'>
						{new Date().toLocaleDateString('he-IL')}
					</div>
					<div className='cell'>שם הסוכן</div>
					<div className='cell'>
						{dataContext.selected?.agentName}
					</div>
					<div className='cell'>מספר הסוכן</div>
					<div className='cell'>
						{dataContext.selected?.agentNumber}
					</div>
				</div>
				<div className='title'>פרטי לקוח</div>
				<div className='row-table'>
					<div className='cell'>
						<span>שם משפחה</span>
						<span>{dataContext.selected?.lastName}</span>
					</div>
					<div className='cell'>
						<span>שם פרטי</span>
						<span>{dataContext.selected?.firstName}</span>
					</div>
					<div className='cell'>
						<span>ת. זהות</span>
						<span>{dataContext.selected?.socialId}</span>
					</div>
					<div className='cell'>
						<span>כתובת</span>
						<span>
							{dataContext.selected?.address &&
								dataContext.selected?.address +
									', ' +
									dataContext.selected?.city}
						</span>
					</div>
					<div className='cell'>
						<span>מס' טלפון/נייד</span>
						<span>{dataContext.selected?.mobile}</span>
					</div>
					<div className='cell'>
						<span>תקופת מנוי</span>
						<span>
							מתאריך:{' '}
							{new Date(
								dataContext.selected?.dateStart,
							).toLocaleDateString('he-IL')}
							<br />
							לתאריך:{' '}
							{new Date(
								dataContext.selected?.dateEnd,
							).toLocaleDateString('he-IL')}
						</span>
					</div>
				</div>
				<div className='title'>פרטי הרכב</div>
				<div className='row-table'>
					<div className='cell'>
						<span>שנת ייצור</span>
						<span>{dataContext.selected?.year}</span>
					</div>
					<div className='cell'>
						<span>ייצרן ודגם</span>
						<span>
							{dataContext.selected?.make &&
								dataContext.selected?.make +
									' ' +
									dataContext.selected?.model}
						</span>
					</div>
					<div className='cell'>
						<span>מספר רישוי</span>
						<span>{dataContext.selected?.licensePlate}</span>
					</div>
				</div>
				<div className='title'>פרטי הכיסוי</div>
				<div className='row-table'>
					<div className='cell'>
						<span>סוג הכיסוי</span>
						<span>
							{dataContext.selected?.subType +
								' ' +
								dataContext.selected?.useType}
						</span>
					</div>
					<div className='cell'>
						<span>סה"כ לתשלום</span>
						<span>{dataContext.selected.subPrice}</span>
					</div>
				</div>
				{renderPaymentTable()}
			</div>
			<div className='buttons'>
				<button
					onClick={() => {
						window.print();
					}}
				>
					הדפס מסמך
				</button>
				<button
					onClick={() => {
						setModal(false);
					}}
				>
					חזרה למערכת
				</button>
			</div>
		</ReportsModal>
	);
};

export default ReportAgent;
