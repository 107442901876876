import he from 'date-fns/locale/he';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateInput = ({
	inForm,
	label,
	selected,
	onChange,
	datesToFilter,
	start,
}) => {
	registerLocale('he', he);
	return (
		<div className={inForm ? 'styled-input' : 'date-input'}>
			<span>{label}</span>
			<DatePicker
				dateFormat='dd/MM/yyyy'
				locale='he'
				selected={selected}
				onChange={(date) => onChange(date, start)}
				filterDate={
					datesToFilter === 'unlimited'
						? null
						: !datesToFilter
						? (date) => {
								return new Date() > date;
						  }
						: start
						? (date) => {
								let maxDate = new Date();
								maxDate.setDate(new Date().getDate() - 30);
								return maxDate > date;
						  }
						: (date) => {
								return new Date() > date;
						  }
				}
			/>
		</div>
	);
};

export default DateInput;
