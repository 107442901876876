import { createContext, useState } from 'react';

export const DataContext = createContext({
	data: [],
	dataToShow: [],
	selected: {},
	update: false,
	delete: false,
	reload: false,
	swap: false,
	loading: false,
	filterData: () => {},
	insertData: () => {},
	setSelected: () => {},
	setUpdateMode: () => {},
	setDeleteModeHandler: () => {},
	setReloadMode: () => {},
	setSwapMode: () => {},
	setLoading: () => {},
});

const DataContextProvider = ({ children }) => {
	const [data, setData] = useState([]);
	const [selected, setSelected] = useState({});
	const [dataToShow, setDataToShow] = useState([]);
	const [update, setUpdate] = useState(false);
	const [swap, setSwap] = useState(false);
	const [reload, setReload] = useState(false);
	const [loading, setLoading] = useState(false);

	const [deleteMode, setDeleteMode] = useState(false);

	const filterDataHandler = (data) => {
		setDataToShow(data);
	};
	const insertDataHandler = (res) => {
		setData(res);
		setDataToShow(res);
	};

	const setSelectedValue = (obj) => {
		setSelected(obj);
	};
	const setDeleteModeHandler = (state) => {
		setDeleteMode(state);
	};

	const updateModeHandler = (value) => {
		return setUpdate(value);
	};
	const reloadModeHandler = (value) => {
		return setReload(value);
	};
	const swapModeHandler = (value) => {
		return setSwap(value);
	};

	const context = {
		data: data,
		dataToShow: dataToShow,
		selected: selected,
		update: update,
		delete: deleteMode,
		reload: reload,
		swap: swap,
		loading: loading,
		filterData: filterDataHandler,
		insertData: insertDataHandler,
		setSelected: setSelectedValue,
		setUpdateMode: updateModeHandler,
		setReloadMode: reloadModeHandler,
		setSwapMode: swapModeHandler,
		setDeleteModeHandler: setDeleteModeHandler,
		setLoading: setLoading,
	};

	return (
		<DataContext.Provider value={context}>{children}</DataContext.Provider>
	);
};

export default DataContextProvider;
