import { AlertBar } from '../../styles/general';
import { ReactComponent as Exit } from '../../assets/images/exit.svg';
const Alert = ({ success, hide, message }) => {
	return (
		<AlertBar success={success}>
			<Exit onClick={hide} />
			<span>{message}</span>
		</AlertBar>
	);
};

export default Alert;
