import { Formik } from 'formik';
import { useContext, useState } from 'react';
import { Navigate } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Eye } from '../assets/images/eye.svg';
import { ReactComponent as EyeHide } from '../assets/images/eyeHide.svg';
import logo from '../assets/images/logo@2x.png';
import Alert from '../components/alerts/Alert';
import { AlertContext } from '../contexts/alertContext';
import { UserContext } from '../contexts/userContext';
import { Title } from '../styles/general';
import {
	CustomField,
	CustomForm,
	FormGroup,
	LoginForm,
	LogoSection,
	Main,
	Submit,
} from '../styles/login';
import { userLogin } from '../utils/api';

const Login = () => {
	const userContext = useContext(UserContext);
	const alertContext = useContext(AlertContext);
	const navigate = useNavigate();
	const [seePass, setSeePass] = useState(false);

	return userContext.auth ? (
		<Navigate to='/' replace />
	) : (
		<Main>
			<LoginForm>
				<Title>כניסה למערכת</Title>
				<Formik
					initialValues={{ username: '', password: '' }}
					onSubmit={async (values, resetForm) => {
						if (!values.username || !values.password)
							return alertContext.handleAlert(
								false,
								'כל השדות הן שדות חובה.',
							);

						const res = await userLogin(values);
						if (!res.success) {
							return alertContext.handleAlert(false, res.message);
						}
						localStorage.setItem('reg-secret', res.token);
						userContext.updateUser(res.data);
						alertContext.handleAlert(true, res.message);
						navigate('/', { replace: true });
						resetForm();
					}}
				>
					<CustomForm>
						<FormGroup>
							<label htmlFor='username'>שם משתמש</label>
							<CustomField id='username' name='username' />
						</FormGroup>
						<FormGroup>
							<label htmlFor='password'>סיסמה</label>
							<CustomField
								id='password'
								name='password'
								type={seePass ? 'text' : 'password'}
							/>
							{!seePass ? (
								<Eye
									onClick={() => {
										setSeePass(true);
									}}
								/>
							) : (
								<EyeHide
									onClick={() => {
										setSeePass(false);
									}}
								/>
							)}
						</FormGroup>
						<FormGroup>
							<Submit type='submit'>התחברות</Submit>
						</FormGroup>
					</CustomForm>
				</Formik>
			</LoginForm>
			<LogoSection>
				<img src={logo} alt='' />
				<p>ר.א צ'ק אין - פתרונות לסוכן ביטוח</p>
				<p>טלפון: 04-9522823 פקס: 076-5100392</p>
				<p>ת.ד: 3139 נהריה</p>
				<p>מייל: R.A.Chakin@Bezeqint.Net</p>
			</LogoSection>
			{alertContext.alert && (
				<Alert
					message={alertContext.message}
					hide={alertContext.hideAlert}
					success={alertContext.success}
				/>
			)}
		</Main>
	);
};

export default Login;
