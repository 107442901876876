import { createContext, useState } from 'react';

export const AlertContext = createContext({
	alert: '',
	success: '',
	message: '',
	handleAlert: (state, message) => {},
	hideAlert: () => {},
});

const AlertContextProvider = ({ children }) => {
	const [success, setSuccess] = useState(true);
	const [alert, setAlert] = useState(false);
	const [message, setMessage] = useState('');

	const handleAlert = (state, message) => {
		setAlert(true);
		setSuccess(state);
		setMessage(message);
		showAlert();
	};
	const showAlert = () => {
		setTimeout(() => {
			setAlert(false);
		}, 5000);
	};

	const hideAlert = () => {
		setAlert(false);
	};
	const context = {
		alert: alert,
		success: success,
		message: message,
		handleAlert: handleAlert,
		hideAlert: hideAlert,
	};

	return (
		<AlertContext.Provider value={context}>
			{children}
		</AlertContext.Provider>
	);
};

export default AlertContextProvider;
