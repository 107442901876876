import { useContext } from "react";
import logo from "../../assets/images/logo@2x.png";
import { DataContext } from "../../contexts/dataContext";
import { ReportBox, ReportsModal } from "../../styles/general";

const ReportClient = ({ setModal }) => {
  const dataContext = useContext(DataContext);

  return (
    <ReportsModal>
      <div className="brand-heading">
        <div className="image">
          <img src={logo} alt="" />
        </div>
        <div className="text">
          <p>ר.א צ'ק אין בע"מ</p>
          <p>טלפון: 04-9522823 פקס: 076-5100392</p>
          <p>ת.ד: 3139 חשמונאים 2 נהריה</p>
          <p>מייל: R.A.Chakin@Bezeqint.Net</p>
        </div>
      </div>
      <div className="intro">
        ר.א.צ'ק אין בע"מ מברכת אותך לרגל הצטרפותך כמנוי של החברה.
        <br />
        השירותים להם הנך זכאי הנם ע"פ סוג המנוי אותו רכשת ובכפוף לכתב השירות
        הרלוונטי.
      </div>
      <div className="dates">
        <p>
          <span>תאריך וזמן הנפקת המסמך:</span>
          <span>{new Date().toLocaleString("he-IL")}</span>
        </p>
        <p>
          <span>תאריך אסמכתא:</span>
          <span>
            {new Date(dataContext.selected.createdAt).toLocaleDateString(
              "he-IL"
            )}
          </span>
        </p>
      </div>
      <div className="sub-table-data">
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>מספר מנוי:</ReportBox>
          <ReportBox>{dataContext.selected.subNumber}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>סה"כ לתשלום:</ReportBox>
          <ReportBox>{dataContext.selected.subPrice}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>שם הסוכן</ReportBox>
          <ReportBox>{dataContext.selected.agentName}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>מספר סוכן:</ReportBox>
          <ReportBox>{dataContext.selected.agentNumber}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>מתאריך:</ReportBox>
          <ReportBox>
            {new Date(dataContext.selected.dateStart).toLocaleDateString(
              "he-IL"
            )}
          </ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>עד תאריך:</ReportBox>
          <ReportBox>
            {new Date(dataContext.selected.dateEnd).toLocaleDateString("he-IL")}
          </ReportBox>
        </div>

        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>שם משפחה:</ReportBox>
          <ReportBox>{dataContext.selected.lastName}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>שם פרטי:</ReportBox>
          <ReportBox>{dataContext.selected.firstName}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}> כתובת:</ReportBox>
          <ReportBox>
            {dataContext.selected.address &&
              dataContext.selected.address + ", " + dataContext.selected?.city}
          </ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>ת"ז:</ReportBox>
          <ReportBox>{dataContext.selected.socialId}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>סוג הרכב:</ReportBox>
          <ReportBox>{dataContext.selected.make}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>מספר רישוי:</ReportBox>
          <ReportBox>{dataContext.selected.licensePlate}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>שנת ייצור:</ReportBox>
          <ReportBox>{dataContext.selected.year}</ReportBox>
        </div>
        <div className="table-row">
          <ReportBox background={"#f2f2f2"}>הכיסוי שרכשת:</ReportBox>
          <ReportBox bold={true}>{dataContext.selected.subType}</ReportBox>
        </div>
        <ReportBox width={"100%"} background={"#f2f2f2"}>
          מנוי זה הוא {dataContext.selected.useType}!
        </ReportBox>
      </div>
      <div className="buttons">
        <button
          onClick={() => {
            window.print();
          }}
        >
          הדפס מסמך
        </button>
        <button
          onClick={() => {
            setModal(false);
          }}
        >
          חזרה למערכת
        </button>
      </div>
    </ReportsModal>
  );
};

export default ReportClient;
