import { lazy, useCallback, useContext, useEffect, Suspense } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Header from './components/Layout/header/Header';
import ReportClient from './components/reports/ReportClient';
import AlertContextProvider, { AlertContext } from './contexts/alertContext';
import DataContextProvider, { DataContext } from './contexts/dataContext';
import { UserContext } from './contexts/userContext';
import { getLoggedAgent, verifyUser } from './utils/api';
import PrivateRoute from './utils/privateRoute';

import PageLoader from './components/Layout/PageLoader';
import Login from './pages/Login';
import { useState } from 'react';

const Clients = lazy(() => import('./pages/Clients'));
const Agents = lazy(() => import('./pages/Agents'));
const Rates = lazy(() => import('./pages/Rates'));
const Claims = lazy(() => import('./pages/Claims'));
const Commissions = lazy(() => import('./pages/Comissions'));

const Payments = lazy(() => import('./pages/Payments'));
const Subscriptions = lazy(() => import('./pages/Subscriptions'));

function App() {
	const userContext = useContext(UserContext);
	const alertContext = useContext(AlertContext);
	const dataContext = useContext(DataContext);
	const { pathname } = useLocation();

	const verifyAccess = useCallback(async () => {
		try {
			const verifiedUser = await verifyUser();
			if (verifiedUser.success && verifiedUser.data.username) {
				userContext.updateUser(verifiedUser.data);
				if (verifiedUser.data.permission !== 10) {
					const agent = await getLoggedAgent(verifiedUser.data.id);
					userContext.updateAgent(agent.data);
				}
			}
		} catch (error) {
			userContext.logoutHandle();
		}
	}, [userContext]);

	const compToRenderHandler = () => {
		return userContext.auth ? <Navigate to='/' replace /> : <Login />;
	};

	useEffect(() => {
		verifyAccess();
	}, [userContext.auth]);

	return userContext ? (
		<DataContextProvider>
			<AlertContextProvider>
				{pathname != '/client_report' && <Header />}
				<Routes>
					<Route path='/login' element={compToRenderHandler()} />
					<Route
						path='/'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Clients />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/agents'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Agents />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/rates'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Rates specialRatesArr={[]} />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/subscriptions'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Subscriptions />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/subscriptions/:clientId'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Subscriptions />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/claims'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Claims />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/claims/:clientId'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Claims />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/payments'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Payments />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/payments/:clientId'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Payments />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/commissions'
						element={
							<PrivateRoute>
								<Suspense fallback={<PageLoader />}>
									<Commissions />
								</Suspense>
							</PrivateRoute>
						}
					/>
					<Route
						path='/client_report'
						element={
							// <PrivateRoute>
							<ReportClient />
							// </PrivateRoute>
						}
					/>
				</Routes>
			</AlertContextProvider>
		</DataContextProvider>
	) : null;
}

export default App;
