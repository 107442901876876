import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function useBackButtonControl(callback) {
	const navigate = useNavigate();
	useEffect(() => {
		const handlePopstate = () => {
			callback(); // Call the provided callback function to update the state
			navigate('', { replace: true }); // Navigates to the current URL to prevent the default back behavior
		};

		window.addEventListener('popstate', handlePopstate);

		return () => {
			window.removeEventListener('popstate', handlePopstate);
		};
	}, [callback, navigate]);
}

export default useBackButtonControl;
