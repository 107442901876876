import { StandardBtn } from '../../styles/btns';
const Button = ({
	clickHandler,
	type,
	background,
	children,
	shadow,
	border,
	position,
	small,
	disabled,
	className,
	width,
}) => {
	return (
		<StandardBtn
			disabled={disabled}
			onClick={clickHandler}
			type={type}
			background={background}
			shadow={shadow}
			border={border}
			position={position}
			small={small}
			className={className}
			width={width}
		>
			{children}
		</StandardBtn>
	);
};

export default Button;
