import { useState, useEffect } from 'react';
import { Tabs, SingleTab, TabIndicator } from '../../styles/btns';
import { useLocation } from 'react-router';

const TabSlider = ({ tabs, width, data, onClick }) => {
	const [activeTab, setActiveTab] = useState({
		index: data,
		width: null,
		transformAgent: '-100%',
	});
	const { pathname } = useLocation();

	const handleClick = (e, index) => {
		tabSwitchHandler(e, index);
		onClick(index);
	};

	const tabSwitchHandler = (e, index) => {
		let transform = '-100%';
		if (!width)
			return setActiveTab((prev) => ({
				...prev,
				index: index,
				width: '33',
				transformAgent: transform,
			}));
		let compWidth = getComputedStyle(e.target).getPropertyValue('width');

		if (index === 1 && pathname !== '/claims') {
			transform = '-80%';
		} else if (index === 2) {
			transform = '-45%';
		}

		setActiveTab((prev) => ({
			...prev,
			index: index,
			width:
				pathname !== '/claims'
					? (+compWidth.split('.')[0] / 300) * 100
					: 50,
			transformAgent: transform,
		}));
		onClick(activeTab.index);
	};

	useEffect(() => {
		setActiveTab((prev) => ({
			...prev,
			width: width || '33',
		}));
	}, []);

	return (
		<Tabs>
			{tabs?.map((tab, index) => {
				return (
					<SingleTab
						className='single-tab'
						key={index}
						onClick={(e) => handleClick(e, index)}
					>
						{tab}
					</SingleTab>
				);
			})}
			<TabIndicator
				transform={`translateX(calc(${activeTab.index} * ${activeTab.transformAgent}));`}
				width={activeTab.width}
			/>
		</Tabs>
	);
};

export default TabSlider;
