import * as FileSaver from 'file-saver';
import { useContext, useEffect, useState } from 'react';
import XLSX from 'sheetjs-style';
import { ReactComponent as SalesIcon } from '../../assets/images/salesBtn.svg';
import { AlertContext } from '../../contexts/alertContext';
import { DataContext } from '../../contexts/dataContext';
import { printReport } from '../../utils/api';
import Button from '../buttons/Button';

const generalStyles = {
	font: { bold: true },
	alignment: {
		readingOrder: 2,
		textRotation: 0,
		vertical: 'center',
		wrapText: true,
	},
	border: {
		top: { style: 'medium', color: { rgb: '000000' } },
		bottom: { style: 'medium', color: { rgb: '000000' } },
		left: { style: 'medium', color: { rgb: '000000' } },
		right: { style: 'medium', color: { rgb: '000000' } },
	},
};

const ExportExcel = ({ startDate, endDate }) => {
	const dataContext = useContext(DataContext);
	const alertContext = useContext(AlertContext);
	const [excelData, setExcelData] = useState([]);
	const [fileName, setFileName] = useState('');

	const fileType =
		'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
	const fileExtension = '.xlsx';

	const exportToExcel = () => {
		//prepareExcelData

		const ws = XLSX.utils.json_to_sheet([...excelData]);

		const range = XLSX.utils.decode_range(ws['!ref']);
		for (let row = range?.s.r; row <= range.e.r; row++) {
			for (let col = range?.s.c; col <= range.e.c; col++) {
				const cellRef = XLSX.utils.encode_cell({ r: row, c: col });

				ws[cellRef].s = generalStyles;
			}
		}

		const wb = {
			Workbook: { Views: [{ RTL: true }] },
			Sheets: { data: ws },
			SheetNames: ['data'],
		};

		const excelBuffer = XLSX.write(wb, {
			bookType: 'xlsx',
			type: 'array',
		});
		const data = new Blob([excelBuffer], { type: fileType });
		FileSaver.saveAs(data, fileName + fileExtension);
	};

	const prepareExcelData = async () => {
		const reportData = await printReport(
			dataContext.selected?.id,
			startDate,
			endDate,
		);

		if (!reportData.success)
			return alertContext.handleAlert(false, reportData.message);

		const formattedFileName = `דוח מנויים עבור ${reportData.data[0]?.agentName} ${reportData.data[0]?.agentNumber}`;
		setFileName(formattedFileName);
		console.log(reportData.data);
		const formattedRows = reportData.data.map((row) => {
			const rowObj = {
				'שם המבוטח': row.clientName,
				'מ. מנוי': row.subNumber,
				'מספר ת.ז': row.clientId,
				סטטוס: row.status,
				'מ. רישוי': row.license,
				'סוג הכיסוי': row.subName,
				מתאריך: new Date(row.dateStart).toLocaleDateString('he-IL'),
				'עד תאריך': new Date(row.dateEnd).toLocaleDateString('he-IL'),
				יצרן: row.make,
				'שנת יצור': row.year,
				'סה"כ שולם': row.totalPayed,
				'עלות מנוי': row.subPrice,
				'סה"כ עמלה': row.commission,
				'התקבל ע"ח': row.payedWithCom,
				'תשלום עמלה': row.payedCom,
			};
			return rowObj;
		});

		setExcelData(formattedRows);
	};

	useEffect(() => {
		if (excelData.length === 0) return;
		exportToExcel();
	}, [excelData]);

	return (
		<Button clickHandler={prepareExcelData} border={true}>
			<span>הדפס דו"ח</span>
			<SalesIcon />
		</Button>
	);
};

export default ExportExcel;
