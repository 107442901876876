import { useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ReactComponent as Arrow } from '../../../assets/images/arrow-down.svg';
import { ReactComponent as Hamburger } from '../../../assets/images/menu.svg';
import logo from '../../../assets/images/logo.png';
import { ReactComponent as Logout } from '../../../assets/images/logout.svg';
import { ReactComponent as MsgIcon } from '../../../assets/images/msgIcon.svg';
import { UserContext } from '../../../contexts/userContext';
import {
	GlobalStyle,
	HeaderBar,
	LogoWrapper,
	UserProfile,
} from '../../../styles/general';
import { logoutUser } from '../../../utils/api';

const Header = () => {
	const userContext = useContext(UserContext);
	const location = useLocation();
	const navigate = useNavigate();

	const [userClick, setUserClick] = useState(false);
	const systemMail = 'r.a.chakin@bezeqint.net';

	const userLogout = async () => {
		const logout = await logoutUser();

		userContext.logoutHandle();

		setUserClick(false);
		navigate('/', { replace: true });
	};

	return (
		<>
			<GlobalStyle />
			<HeaderBar>
				{location.pathname === '/login' ? (
					<LogoWrapper>
						<img src={logo} alt='Chakin Logo' />
					</LogoWrapper>
				) : (
					<>
						{window.innerWidth <= 1440 && (
							<Hamburger
								style={{
									marginInlineStart: '5vmin',
									height: '40px',
									width: '40px',
									cursor: 'pointer',
								}}
								onClick={() => {
									userContext.setMenuOpen(
										!userContext.menuOpen,
									);
								}}
							/>
						)}
						<UserProfile>
							<a
								className='notifications'
								href={'mailto:' + systemMail}
							>
								<MsgIcon />
							</a>
							<div
								className='user'
								onClick={() => {
									setUserClick(!userClick);
								}}
							>
								{userContext.agent && userContext.agent.id
									? userContext.agent.name
									: 'מנהל'}
								<Arrow />
							</div>
							{userClick && (
								<div className='logout' onClick={userLogout}>
									התנתק <Logout />
								</div>
							)}
						</UserProfile>
					</>
				)}
			</HeaderBar>
		</>
	);
};

export default Header;
