import { Form, Field } from 'formik';
import bg from '../assets/images/login-bg.png';
import accent from '../assets/images/accent-bg.png';
import styled from 'styled-components';

export const Main = styled.section`
  background-image: url(${bg});
  background-repeat: no-repeat;
  display: flex;
  @media only screen and (max-width:922px) {
	flex-direction:column;
	width:100%;
	align-items: center;
  }
  @media only screen and (max-width:560px) {
	padding-top: 15vmin;
  }
}`;

export const LoginForm = styled.div`
  background-image: url(${accent});
  background-repeat: no-repeat;
  display: flex;
  flex-direction:column;
  justify-content:center;
  height: 100%;
  width: 50%;
  background-position: 120% top;
  padding-inline-start: clamp(5rem,15vw,15rem);
  @media only screen and (max-width:1200px) {
	flex-grow:1;
  }
  @media only screen and (max-width:922px) {
	width:100%;
	padding: unset;
  }
}`;

export const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  font-size: var(--fz-accent);
  gap:10px;
  position:relative;
  svg{
	max-width:fit-content;
	max-height:22px;
	position:absolute;
	left:3%;
	bottom:15%;
	}

}`;

export const Submit = styled.button`
  min-height:50px;
  max-width:35%;
  margin-top: 1em;
  font-weight:700;
  padding-block:0.3em;
  border-radius: var(--border-radius);
  border:none;
  box-shadow: var(--box-shadow-600);
  background: linear-gradient(180deg, rgba(39,160,231,0.88) 54%, rgba(39,160,231,0.54) 100%);
  @media only screen and (max-width:560px) {
	min-width:150px;
	max-width:unset;
	  }
}`;

export const CustomForm = styled(Form)`
  max-width:70%;
  display:flex;
  flex-direction:column;
  gap: 20px;
  margin-top: 30px;
  @media only screen and (max-width:922px) {
	width:min(450px,95%);
	margin-inline:auto;
  }
}`;

export const CustomField = styled(Field)`
	border:none;
	background-color: var(--c-neutral-300-1);
	border-radius: var(--border-radius);
	padding: 0.4em 1em;

	&:focus{
		outline:none;
	}

}`;

export const LogoSection = styled.div`
  position: relative;
  width:50%;
  display:flex;
  flex-direction:column;
  justify-content:flex-end;
  gap:1.25rem;
  padding-bottom: 2em;
  img{
	  height: 50%;
	  width: 50%;
	  position: absolute;
	  left: 50%;
	  top: 50%;
	  transform: translate(-50%,-50%);
	}
  p{
	  font-size: var(--fz-accent);
	  max-width:35%;
	  font-weight:300;
	  margin-inline:auto;
	  width:100%;
	  &:last-of-type{
		  margin-top:-1em;
	  }
  }
  @media only screen and (max-width:1560px) {
	gap:0.65rem;
	p{
		max-width:max-content;
	}
  }
  @media only screen and (max-width:1200px) {
	max-width: 30%;
	justify-content:center;
	img{
		position:unset;
		transform:unset;
		height: auto;
		margin-inline: auto;
	}
	p{
		text-align:center;
	}
  }
  @media only screen and (max-width:922px) {

	img{
		display:none;
	}
  }
  @media only screen and (max-width:922px) {

	max-width: 100%;
  }
}`;
