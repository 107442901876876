import axios from 'axios';
import {
	BANK_BRANCH_ID,
	BANK_CODES_ID,
	DATA_GOV_URL,
	fetchDelete,
	fetchGet,
	fetchPost,
	fetchPut,
	GLOBAL_URL,
	RESOURCE_ID,
} from './global';

//check connection
export const checkConnection = async () => {
	return await fetchGet(`${GLOBAL_URL}status/`);
};

//user login function
export const userLogin = async (obj) => {
	try {
		return await fetchPost(`${GLOBAL_URL}users/userLogin`, obj);
	} catch (err) {}
};
export const verifyUser = async () => {
	return await fetchGet(`${GLOBAL_URL}users/verifyUser`);
};
export const logoutUser = async () => {
	return await fetchGet(`${GLOBAL_URL}users/logout`);
};
export const getLoggedAgent = async (id) => {
	return await fetchGet(`${GLOBAL_URL}agents/getAgentByUserId?id=${id}`);
};
export const insertUser = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}users/insertUser`, obj);
};
export const getUser = async (id) => {
	return await fetchGet(`${GLOBAL_URL}users/getUser?id=${id}`);
};
export const updateUser = async (obj, id) => {
	return await fetchPut(`${GLOBAL_URL}users/updateUser?id=${id}`, obj);
};

//agents
export const getAllAgents = async () => {
	return await fetchGet(`${GLOBAL_URL}agents/getAllAgents`);
};
export const getAgentByUserId = async (id) => {
	return await fetchGet(`${GLOBAL_URL}agents/getAgentByUserId?id=${id}`);
};

export const insertAgent = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}agents/insertAgent`, obj);
};
export const updateAgent = async (obj, id) => {
	return await fetchPut(`${GLOBAL_URL}agents/updateAgent?id=${id}`, obj);
};
export const getAgentById = async (id) => {
	return await fetchGet(`${GLOBAL_URL}agents/getAgentById?id=${id}`);
};
export const getChildAgents = async (id, type) => {
	return await fetchGet(
		`${GLOBAL_URL}agents/getChildAgents?id=${id}&type=${type}`,
	);
};
export const addToAgentsCommission = async (data) => {
	return await fetchPost(`${GLOBAL_URL}agents/addToAgentsCommission`, data);
};

//clients
export const getAllClients = async () => {
	return await fetchGet(`${GLOBAL_URL}clients/getAllClients`);
};
export const getClientsWithActiveSubs = async () => {
	return await fetchGet(`${GLOBAL_URL}clients/getClientsWithActiveSubs`);
};
export const getClientsByAgentId = async (id) => {
	return await fetchGet(`${GLOBAL_URL}clients/getClientsByAgentId?id=${id}`);
};
export const insertClient = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}clients/createClient`, obj);
};
export const updateClient = async (obj, id) => {
	return await fetchPut(`${GLOBAL_URL}clients/updateClient?id=${id}`, obj);
};
export const getClient = async (id) => {
	return await fetchGet(`${GLOBAL_URL}clients/getClient?id=${id}`);
};

//form
export const getForm = async (page) => {
	return await fetchGet(`${GLOBAL_URL}forms/getFormByPage?page=${page}`);
};

//rates
export const insertRate = async (rate) => {
	return await fetchPost(`${GLOBAL_URL}rates/insertNewRate`, rate);
};
export const getAllRates = async () => {
	return await fetchGet(`${GLOBAL_URL}rates/getAllRates`);
};
export const getRate = async (id) => {
	return await fetchGet(`${GLOBAL_URL}rates/getRate?id=${id}`);
};
export const getRatesPerUseType = async (use) => {
	return await fetchGet(`${GLOBAL_URL}rates/getRatesPerUseType?use=${use}`);
};
export const getRateFormats = async (id) => {
	return await fetchGet(`${GLOBAL_URL}rates/getRateFormats`);
};
export const updateRate = async (id, obj) => {
	return await fetchPut(`${GLOBAL_URL}rates/updateRate?id=${id}`, obj);
};
export const getRateParent = async (id) => {
	return await fetchGet(`${GLOBAL_URL}rates/getRateParent?id=${id}`);
};
export const getAllSubTypes = async () => {
	return await fetchGet(`${GLOBAL_URL}subTypes/getAllSubTypes`);
};

export const getAllTypesPerUseType = async (use) => {
	return await fetchGet(
		`${GLOBAL_URL}subTypes/getAllTypesPerUseType?use=${use}`,
	);
};

export const getSubTypes = async (use, children, parent) => {
	return await fetchGet(
		`${GLOBAL_URL}subTypes/getSubTypes?use=${use}&children=${children}&parent=${parent}`,
	);
};
export const getSubTypeById = async (id) => {
	return await fetchGet(`${GLOBAL_URL}subTypes/getSubTypeById?id=${id}`);
};

//subscriptions
export const getSubscriptions = async () => {
	return await fetchGet(`${GLOBAL_URL}subscriptions/getAllSubscriptions`);
};
export const getSubscriptionById = async (id) => {
	return await fetchGet(`${GLOBAL_URL}subscriptions/getSubById?id=${id}`);
};
export const getSubscriptionsByAgent = async (id) => {
	return await fetchGet(
		`${GLOBAL_URL}subscriptions/getSubscriptionsByAgent?id=${id}`,
	);
};
export const getSubscriptionsByClient = async (id) => {
	return await fetchGet(
		`${GLOBAL_URL}subscriptions/getSubscriptionsByClient?id=${id}`,
	);
};
export const addSubscription = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}subscriptions/addSubscription`, obj);
};

export const editSubStatus = async (body) => {
	return await fetchPut(`${GLOBAL_URL}subscriptions/editSubStatus`, body);
};

export const editSubDetails = async (body) => {
	return await fetchPut(`${GLOBAL_URL}subscriptions/editSubDetails`, body);
};
export const cancelExpiredSubs = async (body) => {
	return await fetchPut(`${GLOBAL_URL}subscriptions/cancelExpiredSubs`);
};
export const handleSwapSub = async (id, newSub) => {
	return await fetchPost(
		`${GLOBAL_URL}subscriptions/handleSwapSub?prev=${id}`,
		newSub,
	);
};
export const getRelatedSubPayments = async (id) => {
	return await fetchGet(
		`${GLOBAL_URL}subscriptions/getRelatedPayments?id=${id}`,
	);
};

//Insurance info

export const newInsInfo = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}insuranceInfo/addInsuranceInfo`, obj);
};

//Car Information
export const getCarInfo = async (licensePlate) => {
	delete axios.defaults.headers.common['Authorization'];
	let { data } = await axios.get(
		`${DATA_GOV_URL}?resource_id=${RESOURCE_ID}&q=${licensePlate}`,
	);
	return data;
};

//delete listing
export const deleteListing = async (path, id) => {
	return await fetchDelete(`${GLOBAL_URL + path}/deleteListing?id=${id}`);
};

export const getLastListing = async (route) => {
	return await fetchGet(`${GLOBAL_URL + route}/getLastListing`);
};

// special rates
export const insertSpecialRate = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}specialRates/insertSpecialRate`, obj);
};
export const getSpecialRate = async (rateId, agentId) => {
	return await fetchGet(
		`${GLOBAL_URL}specialRates/getSpecialRate?rateId=${rateId}&agentId=${agentId}`,
	);
};

//CLAIMS
export const addNewClaim = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}claims/addNewClaim`, obj);
};
export const getAllClaims = async (obj) => {
	return await fetchGet(`${GLOBAL_URL}claims/getAllClaims`);
};
export const editClaim = async (obj, id) => {
	return await fetchPut(`${GLOBAL_URL}claims/editClaim?id=${id}`, obj);
};

//PAYMENTS
export const newPaymentListing = async (obj) => {
	return await fetchPost(`${GLOBAL_URL}payments/newPaymentListing`, obj);
};
export const getMethods = async () => {
	return await fetchGet(`${GLOBAL_URL}payments/getMethods`);
};
export const getPayments = async (id) => {
	return await fetchGet(`${GLOBAL_URL}payments/getPayments?agentId=${id}`);
};
export const getPaymentById = async (id) => {
	return await fetchGet(`${GLOBAL_URL}payments/getPaymentById?id=${id}`);
};
export const getPaymentBySubId = async (id) => {
	return await fetchGet(
		`${GLOBAL_URL}payments/getPaymentById?id=${id}&sub={true}`,
	);
};
export const getChecksByPaymentId = async (id) => {
	return await fetchGet(
		`${GLOBAL_URL}payments/getChecksByPaymentId?id=${id}`,
	);
};
export const cancelPayment = async (id) => {
	return await fetchPut(`${GLOBAL_URL}payments/cancelPayment?id=${id}`);
};

export const updatePayment = async (id, body) => {
	return await fetchPut(`${GLOBAL_URL}payments/updatePayment?id=${id}`, body);
};
export const completePayment = async (id, body) => {
	return await fetchPut(
		`${GLOBAL_URL}payments/completePayment?id=${id}`,
		body,
	);
};
export const getRelatedPayments = async (id) => {
	return await fetchGet(`${GLOBAL_URL}payments/getRelatedPayments?id=${id}`);
};

export const getTerminal = async (body) => {
	return await fetchPost(`${GLOBAL_URL}payments/getPaymentTerminal`, body);
};
export const getCreditCards = async () => {
	return await fetchGet(`${GLOBAL_URL}payments/getCreditCards`);
};
export const validateCreditCard = async (payment) => {
	return await fetchPost(`${GLOBAL_URL}zcredit/validateCreditCard`, payment);
};
export const commitTransaction = async (paymentData) => {
	return await fetchPost(
		`${GLOBAL_URL}zcredit/commitTransaction`,
		paymentData,
	);
};

//COMMISSIONS
export const newCommissionListing = async (body) => {
	return await fetchPost(
		`${GLOBAL_URL}commissions/addCommissionListing`,
		body,
	);
};

export const getAllCommissions = async (body) => {
	return await fetchGet(`${GLOBAL_URL}commissions/getAllCommissions`, body);
};

export const printReport = async (agentId, dateStart, dateEnd) => {
	return await fetchGet(
		`${GLOBAL_URL}subscriptions/printReport?agent=${agentId}&start=${dateStart}&end=${dateEnd}`,
	);
};
export const payMultipleCommissions = async (body) => {
	return await fetchPut(
		`${GLOBAL_URL}commissions/payMultipleCommissions`,
		body,
	);
};
export const produceICountInvoice = async (data) => {
	return await fetchPost(`${GLOBAL_URL}icount/produceInvoice`, data);
};

export const getBankInformation = async () => {
	delete axios.defaults.headers.common['Authorization'];
	let { data } = await axios.get(
		`${DATA_GOV_URL}?resource_id=${BANK_CODES_ID}&limit=2000`,
	);

	if (!data.success) return false;
	return data.result?.total > 0 ? data.result.records : [];
};
export const getBankBranches = async () => {
	delete axios.defaults.headers.common['Authorization'];
	let { data } = await axios.get(
		`${DATA_GOV_URL}?resource_id=${BANK_BRANCH_ID}&limit=2000`,
	);

	if (!data.success) return false;

	return data.result?.total > 0 ? data.result?.records : [];
};
