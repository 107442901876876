import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataContext } from './dataContext';

export const UserContext = createContext({
	auth: false,
	user: {},
	agent: {},
	menuOpen: false,
	setMenuOpen: () => {},
	updateUser: (token, id) => {},
	updateAgent: (agent) => {},
	logoutHandle: () => {},
});

const UserContextProvider = ({ children }) => {
	const [user, setUser] = useState({});
	const [agent, setAgent] = useState({});
	const [authenticated, setAuthenticated] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const dataContext = useContext(DataContext);
	let navigate = useNavigate();

	const updateUserHandler = async (user) => {
		setAuthenticated(true);
		setUser((prev) => ({
			...prev,
			id: user?.id,
			permission: user?.permission,
		}));
	};
	const updateAgentHandler = (agentobj) => {
		setAgent((prev) => ({
			...prev,
			id: agentobj?.id,
			name: agentobj?.firstName + ' ' + agentobj?.lastName,
			agentNumber: agentobj?.agentNumber,
			company: agentobj?.company,
		}));
	};
	const logoutHandler = () => {
		localStorage.removeItem('reg-secret');
		setAuthenticated(false);
		dataContext.insertData([]);
		setAgent({});
		navigate('/login', { replace: true });
	};

	const context = {
		auth: authenticated,
		user,
		agent,
		menuOpen,
		setMenuOpen,
		updateUser: updateUserHandler,
		updateAgent: updateAgentHandler,
		logoutHandle: logoutHandler,
	};

	return (
		<UserContext.Provider value={context}>{children}</UserContext.Provider>
	);
};

export default UserContextProvider;
