import axios from 'axios';
import { AES, enc } from 'crypto-js';
import Payment from 'payment';

// export const GLOBAL_URL = 'http://localhost:3001/chakin/';
export const GLOBAL_URL = 'https://chakinstage.co.il:3001/chakin/';
export const DATA_GOV_URL = 'https://data.gov.il/api/3/action/datastore_search';
export const RESOURCE_ID = '053cea08-09bc-40ec-8f7a-156f0677aff3';
export const BANK_BRANCH_ID = '1c5bc716-8210-4ec7-85be-92e6271955c2';
export const BANK_CODES_ID = 'ebb61778-e34c-4e67-8fcf-0e643d9cf8c2';

export const ENCRYPT_SECRET = 'VNfTUoPHy3HYtjFi30DWO851ZrNxsIFY';

export const fetchGet = async (url) => {
	const { data } = await axios.get(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
	});
	return data;
};

export const fetchPost = async (
	url,
	obj,
	content_type = 'application/json',
) => {
	const { data } = await axios.post(url, obj, {
		withCredentials: true,
		mode: 'cors',
		// credentials: 'include',
		headers: {
			'Content-Type': content_type,
			// 'Access-Control-Allow-Origin': 'https://chakinstage.co.il',
			'Access-Control-Allow-Origin': 'http://chakinstage.co.il',
		},
	});
	return data;
};

// 'Access-Control-Allow-Origin': 'http://localhost:3300/chakin'
export const fetchPut = async (url, obj, content_type = 'application/json') => {
	const { data } = await axios.put(url, obj, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			// 'Access-Control-Allow-Origin': 'https://chakinstage.co.il',
		},
	});
	return data;
};

export const fetchDelete = async (
	url,
	obj,
	content_type = 'application/json',
) => {
	const { data } = await axios.delete(url, {
		withCredentials: true,
		mode: 'cors',
		credentials: 'include',
		headers: {
			'Content-Type': content_type,
			// 'Access-Control-Allow-Origin': 'https://chakinstage.co.il',
		},
		data: obj,
	});
	return data;
};

export const formatLicensePlate = (num) => {
	if (num.length === 7) {
		num = num.slice(0, 2) + '-' + num.slice(2, 8);
		num = num.slice(0, 6) + '-' + num.slice(6, 9);
	} else if (num.length === 8) {
		num = num.slice(0, 3) + '-' + num.slice(3, 9);
		num = num.slice(0, 6) + '-' + num.slice(6, 10);
	}
	return num;
};

export const calculateMonthlyCom = (rowsArr) => {
	let initailCom = 0;

	let totlaToPay = rowsArr.reduce(
		(accumulator, currValue) => accumulator + currValue?.total,
		initailCom,
	);

	return totlaToPay || 0;
};

function clearNumber(value = '') {
	return value.replace(/\D+/g, '');
}

export function formatCreditCardNumber(value) {
	if (!value) {
		return value;
	}
	value = AES.decrypt(value, ENCRYPT_SECRET).toString(enc.Utf8);
	const issuer = Payment.fns.cardType(value);
	const clearValue = clearNumber(value);
	let nextValue;

	switch (issuer) {
		case 'amex':
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10,
			)} ${clearValue.slice(10, 15)}`;
			break;
		case 'dinersclub':
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				10,
			)} ${clearValue.slice(10, 14)}`;
			break;
		default:
			nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
				4,
				8,
			)} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
			break;
	}

	return nextValue.trim();
}

export function formatCVC(value, prevValue, allValues = {}) {
	const clearValue = clearNumber(value);
	let maxLength = 4;

	if (allValues.number) {
		const issuer = Payment.fns.cardType(allValues.number);
		maxLength = issuer === 'amex' ? 4 : 3;
	}

	return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value) {
	const clearValue = clearNumber(value);

	if (clearValue.length >= 3) {
		return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
	}

	return clearValue;
}

export function formatFormData(data) {
	return Object.keys(data).map((d) => `${d}: ${data[d]}`);
}

export function formatInvoiceData(client, sub, payment) {
	console.log(client, sub, payment);
	const clientInfo = {};
	const subInfo = {};
	const paymentInfo = {};
	const paymentMethod = 'credit' || 'checks' || 'cash';
	return { clientInfo, subInfo, paymentInfo, paymentMethod };
}
