import styled, { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
:root {

  /* Animations */


  /* sizes */
  --fz-title: 2.1875rem;
  --fz-sub-title: 1.5rem;
  --fz-accent: 1.375rem;
  --fz-regular: 1.25rem;
  --fz-small: 1rem;

  /* Fonts */
 

  /* Fonts: Weight */


  /* Colors */
  --c-neutral-100: #FFFFFF;
  --c-neutral-200: #F6F6F6;
  --c-neutral-250: #EEEEEE;
  --c-neutral-300: #E3E3E3;
  --c-neutral-300-1: #E3E3E354;
  --c-neutral-600: #474545;
  --c-neutral-700: #2C2C2C;
  
  --c-accent-100: #D9F3F1;
  --c-accent-200: #E7F6F5;
  --c-accent-200-a: #e8f7f699;
  --c-accent-300: #A2F6DB;


  --c-main-100: #DCF0FB;
  --c-main-400: #27A0E7;
  --c-main-500: #80BBE5;
  --c-main-600: #2783BA;
  --c-main-700: #154866;


  /* Colors: States */
  --c-active-nav-item:var(--c-main-600);
  --disabled-cell:rgb(148 163 184 / 28%);
  --c-error: #c81d26cc;
  --c-success:#16ca58cc;
  --c-warning:#eD7D3A;
  --c-standby:#FFC145;

  /* Borders */
  --border-radius:23px;

  /* Heights */
  --header-height:92px;

  /* Backgrounds */
  --gradient-bg:linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(128, 187, 229, 1) 100%
  );
  --gradient-bg-100:linear-gradient(180deg, rgba(216,236,251,1) 0%, rgba(209,229,248,1) 100%);

  /* Shadows */
  --box-shadow-100: 0 0 14px #0000001A;
  --box-shadow-150: 0 9px 33px #0000000D;
  --box-shadow-200: 0 0 12px #00000014;
  --box-shadow-300: 0 3px 6px #00000029;
  --box-shadow-400: 0 0 7px #15486638;
  --box-shadow-500: 0 0 38px #0000001A;
  --box-shadow-550: 0 0 31px #0000001F;
  --box-shadow-600: 0 0 14px #F4001538;

}
`;

export const HeaderBar = styled.header`
  position: fixed;
  width:100%;
  min-height:var(--header-height);
  background-color: var(--c-neutral-100);
  box-shadow: var(--box-shadow-150);
  display:flex;
  justify-content: flex-end;
  align-items:center;
  z-index:1;
}`;

export const LogoWrapper = styled.div`
  position: absolute;
  border-radius: 100%;
  right: 3%;
  top: 10px;
  background-color: var(--c-neutral-100);
  padding: 1rem;
  box-shadow: var(--box-shadow-200);
  height: 115px;
  aspect-ratio: 1;
  display:flex;
  img{
	margin: auto;
    transform: scale(1.3);
  }
}`;

export const UserProfile = styled.div`
  position:relative;
  display:flex;
  margin-inline-start: auto;
  margin-inline-end: 4em;
  gap:1.5em;
  font-size:var(--fz-accent);
  font-weight:650;
  align-items:center;
  cursor:pointer;
  color:var(--c-main-700);
  a{
	  background:var(--gradient-bg-100);
	  padding:0.5em;
	  border-radius:0.5em;
	  position:relative;
  }
  span{
		font-size:0.9em;
		position:absolute;
		color:var(--c-neutral-100);
		background:#F40015;
		line-height:1;
		padding: 0.05em 0.35em;
		border-radius:2em;
		top:-0.5em;
		right:-0.5em;
	}
}`;

export const Title = styled.div`
  font-size: var(--fz-title);
  font-weight:700;
  ${({ color }) =>
		color &&
		css`
	color:var(--c-main-700);
}`}
@media only screen and (max-width:922px) {
	width:min(450px,95%);
	margin-inline:auto;
	text-align:center;
  }
}`;

export const MainGrid = styled.section`
  display:grid;
  grid-template-columns:repeat(100,1fr);
}`;

export const Nav = styled.div`
    grid-column: span 12;
    background-color: var(--c-main-600);
    z-index:1;
    box-shadow: -12px 0 38px rgba(0 ,0 ,0 ,0.1);
    display:flex;
    flex-direction:column;
    padding-top:var(--header-height);
    align-items:center;
    gap: 20px;
	width:max(12vw,220px);
    a{
      font-size:var(--fz-sub-title);
      color:var(--c-neutral-100);
      text-decoration: none;
      display: flex;
      font-weight: 600;
      gap: 15px;
      width: 80%;
      align-items: center;
      align-self: baseline;
      padding-block: 0.5em;
      padding-inline-start: 1.2em;
      border-radius: 42px 0 0 42px;
    }

	svg{
		height: 35px;
		width: 35px;
	}

	@media only screen and (max-width:1440px) {
		position:fixed;
		z-index: 11;
		height: 100%;
		transition:all 0.25s ease;


		${({ hamburger }) =>
			hamburger &&
			css`
				transform:translatey(-100%);
		}`}

		${({ showMenu }) =>
			showMenu &&
			css`
				transform:translatey(0) !important;
		}`}
	  }
  }
`;
export const MainContent = styled.div` 
    grid-column: span 88;
	background-color:var(--c-neutral-250);
	padding-inline-start: 1.5em;
	padding-block: calc(var(--header-height) + 3em) 3vmin;
	max-height:100%;
	overflow:auto;

	
	/* width */
	&::-webkit-scrollbar {
	width: 1vmin;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
	background: var(--c-main-500);
	border-radius:100vw;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	background: var(--c-main-600);
	}
	@media only screen and (max-width:1440px) {
		grid-column: span 100;
		padding-inline:4vmin;
	 }
  }
`;

export const MainContainer = styled.div`
  width:100%;
  max-width:1220px;
  background-color:var(--c-neutral-100);
  border-radius:var(--border-radius);
  box-shadow: var(--box-shadow-500);
  position:relative;
  z-index:1;
  

	${({ fullWidth }) =>
		!fullWidth &&
		css`
		max-width:1500px;
	}`}
	${({ fixed }) =>
		fixed &&
		`
		position:fixed;
	}`}
	${({ credit }) =>
		credit &&
		`
		inset: 0;
		margin: auto;
		max-width: fit-content;
		max-height: fit-content;
		padding: 32px;
	}`}	
}`;

export const AlertBar = styled.div`
position:fixed;
z-index:11;
padding:1.2vmin 5vmin;
font-size:var(--fz-accent);
font-weight:700;
bottom:5%;
left:50%;
transform:translateX(-50%);
background-color:var(--c-error);
color:var(--c-neutral-100);
border-radius:var(--border-radius);
box-shadow:var(--box-shadow-100);
svg{
	position:absolute;
	right:5%;
	top:20%;
	height:1.5vmin;
	cursor:pointer;
}
path{
	fill:var(--c-neutral-100);
}
${({ success }) =>
	success &&
	css`
background-color:var(--c-success)
}`}
}`;

export const WarningModal = styled.div`
	position:fixed;
	top:50%;
	left:50%;
	min-width:25%;
	transform:translate(-50%,-50%);
	background-color:var(--c-neutral-100);
	box-shadow: var(--box-shadow-400);
	padding:3vmin 2vmin;
	border-radius:var(--border-radius);
	display:flex;
	flex-direction:column;
	align-items:center;
	gap:1vmin;
	z-index:1;

	span{
		max-width:55%;
		text-align:center;
		font-size:var(--fz-accent);
	}

	svg{
		border-radius:100%;
		border:0.5vmin solid var(--c-main-400);
		padding:1vmin;
		height:8.5vmin;
		animation:pulse 0.75s forwards;
		polygon,rect{
			fill:var(--c-main-400);
		}
	}

	div{
		margin-top:2vmin;
		display:flex;
		justify-content:space-evenly;
		width:100%;
	}
	button{
		margin-inline-start:unset;
		svg{
			all:unset;
		}
	&:last-of-type{
		color:var(--c-main-700);
		svg{
			path{
				fill:var(--c-main-700);
			}
		}
	}
	}
	p{
		text-align:center;
		max-width:80%;
		
	}

	.warning-checkbox{
		display:flex;
		align-items: center;
		max-width: fit-content;
		gap: 1vmin;
		color:var(--c-main-700);
		label{
			position:relative;
			cursor:pointer;
			&::before{
				content:'';
				position:absolute;
				height:85%;
				top:50%;
				aspect-ratio:1;
				border:1px solid var(--c-main-400);
				border-radius:5px;
				transform:translate(calc(100% + 10px),-50%);
				transition:all 0.2s ease;
			}
		}
	}

}`;

export const Loader = styled.div`
	position:absolute;
	z-index:11;
	inset:0;
	background-color:var(--c-neutral-100);
	border-radius:var(--border-radius);

	table{
		overflow: hidden;
		width: 75%;
		margin: 12% 2% 0;
		pointer-events:none;

		td{
			height:45px;
			position:relative;
			background-color:var(--c-neutral-200);
			animation: loading 1s ease-in-out infinite;
			// &::after{
			// 	--gradient: linear-gradient(to right, #ffffff00, #ffffffff, #ffffff00);
			// 	position:absolute;
			// 	content:'';
			// 	top: 50%;
			// 	width: 50px;
			// 	height: 100%;
			// 	background: var(--gradient);
			// 	transform: translateY(-50%) skew(-20deg);
			// }
		}
	}
}`;

export const AutoCompleteList = styled.div`
	width:90%;
	background-color:var(--c-main-100);
    border-radius: 0 0 23px 23px;
   	height: 20vh;
    overflow-y: scroll;

	/* width */
	&::-webkit-scrollbar {
	width: 0.7vmin;
	}

	/* Track */
	&::-webkit-scrollbar-track {
	background: #f1f1f1;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
	background: var(--c-neutral-300);
	border-radius:100vw;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
	background: #555;
	}
	

	.list{
		display:flex;
		flex-direction:column;
		justify-content:unset;
		.listItem{
			display:block;
		    padding: 0.5vmin 2vmin;
    		margin-top: unset;
			text-align:right;
			border-bottom:1px solid var(--c-main-400);
			
			span{
				max-width:100%;
				font-size:1.1em;
				&:first-of-type{
					margin-inline-end:2vmin;
					min-width:78px;
					display:inline-block;
				}
			}
			&:hover, &.active{
				cursor:pointer;
				background-color:var(--c-neutral-200);
			}
		}
	}
	.linkToNew{
		font-size:1.3em;
		text-decoration:underline;
	}

}`;

export const AutoCompleteInput = styled.input`
width: 100%;
margin-bottom: -3vmin;
background: var(--c-main-100);
border: none;
border-bottom: 1px solid var(--c-main-600);
padding: 1vmin 2vmin;
border-radius: 23px 23px 0 0;

&:focus{
	outline:none;
}
}`;

export const ReportsModal = styled.div`
	position:fixed;
	inset:0;
	background:var(--c-neutral-100);
	z-index:999;
	padding:3vmin;
	overflow:scroll;


	.brand-heading{
		display:flex;
		gap:2vmin;
		align-items:center;

		.image{
			max-width:120px;
		}

		.text{
			p{
				font-size:1.6rem;
				margin:unset;
				line-height:1.1;
				
				&:first-of-type{
					font-weight:700;
				}
			}
		}
	}
	.dates{
		margin-block:2vmin;
		p{
			display:flex;
			gap:1vmin;
			font-size:1.3rem;
			font-weight:700;
			color:var(--c-main-400);
		}
	}

	.intro{
		margin-top:2vmin;
		font-size:1.1rem;
	}

	.sub-table-data{
		display:flex;
		flex-wrap:wrap;
		gap:15px 20px;
	    max-width:550px;
	}

	.table-row{
		display:flex;
		gap:15px;
	}

	.buttons{
	    position: absolute;
		margin-top: 2vmin;
		top: 5%;
		right: 50%;
		transform: translatex(50%);
		cursor: pointer;
		margin-top:2vmin;
		button{
			margin-left:20px;
			border:unset;
			font-weight:700;
			border-radius:16px;
			padding:0.5em;
			cursor:pointer;
			background-color: var(--c-accent-300);
			&:hover{
				opacity:0.8;
			}
		}
	}
	.table{
		font-size:18px;
		margin-top:20px;
		max-width:650px;
		.row-table{
			width:100%;
			display:flex;

			.cell{
				border:1px solid;
				flex-grow:1;
				padding-block:0.2em;
				text-align:center;

				span{
					display:block;
					min-height:20px;
					&:first-of-type{
						border-bottom:1px solid
					}
				}
			}

		}
		.title{
			margin-top:20px;
			text-decoration:underline;
		}
	}
}`;

export const ReportBox = styled.div`
	font-size:1.2rem;
	font-weight:600;
	padding:0.3em;
	width:125px;
	border:4px double;

	${({ background }) =>
		background &&
		css`
		background-color:${background};
	}`}
	${({ bold }) =>
		bold &&
		css`
		font-weight:700;
		font-size:0.95em;
	}`}
	${({ width }) =>
		width &&
		css`
	width:100%;
	text-align:center;
	}`}
}`;
