import { createContext, useState } from "react";

export const ActionsBarContext = createContext({
	open:'',
	handleState: (state)=>{}
});

const ActionsBarProvider = ({ children }) => {

  const [open, setOpen] = useState(true);



  const context = {
	open:open,
	handleState: (state)=>{setOpen(state)}
  };

  return (
    <ActionsBarContext.Provider value={context}>{children}</ActionsBarContext.Provider>
  );
};

export default ActionsBarProvider;
