import { useContext } from 'react';
import { useLocation } from 'react-router';
import ActionsBarProvider from '../contexts/actionsBarContext';
import { AlertContext } from '../contexts/alertContext';
import { DataContext } from '../contexts/dataContext';
import Login from '../pages/Login';
import { MainContent, MainGrid } from '../styles/general';
import Alert from './alerts/Alert';
import ActionsBar from './Layout/sideBars/actionsBar';
import NavBar from './Layout/sideBars/navBar';

const Layout = ({ children }) => {
	const dataContext = useContext(DataContext);
	const alertContext = useContext(AlertContext);
	const location = useLocation();

	return (
		<>
			{location.pathname !== '/login' ? (
				<>
					<MainGrid>
						<ActionsBarProvider>
							<NavBar />
							<MainContent>{children}</MainContent>
							{location.pathname !== '/rates' && <ActionsBar />}
						</ActionsBarProvider>
					</MainGrid>

					{alertContext.alert && (
						<Alert
							message={alertContext.message}
							hide={alertContext.hideAlert}
							success={alertContext.success}
						/>
					)}
				</>
			) : (
				<>
					<Login />
				</>
			)}
		</>
	);
};

export default Layout;
