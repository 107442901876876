import { useContext } from 'react';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as X } from '../../../assets/images/x.svg';
import { ReactComponent as agentsIcon } from '../../../assets/images/agents.svg';
import { ReactComponent as claimsIcon } from '../../../assets/images/claims.svg';
import { ReactComponent as clientsIcon } from '../../../assets/images/clients.svg';
import logo from '../../../assets/images/logo.png';
import { ReactComponent as paymentsIcon } from '../../../assets/images/payments.svg';
import { ReactComponent as ratesIcon } from '../../../assets/images/rates.svg';
import { ReactComponent as subsIcon } from '../../../assets/images/subs.svg';
import { ReactComponent as commissionsIcon } from '../../../assets/images/trophy.svg';
import { UserContext } from '../../../contexts/userContext';
import { Nav } from '../../../styles/general';
import { useState } from 'react';

const NavBar = () => {
	const location = useLocation();
	const userContext = useContext(UserContext);
	const [hamburger, setHamburger] = useState(window.innerWidth <= 1440);

	const NAV_LINKS = [
		{
			title: 'סוכנים',
			icon: agentsIcon,
			path: '/agents',
			permission: [2, 3, 10],
		},
		{
			title: 'לקוחות',
			icon: clientsIcon,
			path: '/',
			permission: [1, 2, 3, 10],
		},
		{
			title: 'מנויים',
			icon: subsIcon,
			path: '/subscriptions',
			permission: [1, 2, 3, 10],
		},
		{
			title: 'תביעות',
			icon: claimsIcon,
			path: '/claims',
			permission: [1, 2, 3, 10],
		},
		{
			title: 'מחירונים',
			icon: ratesIcon,
			path: '/rates',
			permission: [10],
		},
		{
			title: 'תשלומים',
			icon: paymentsIcon,
			path: '/payments',
			permission: [1, 2, 3, 10],
		},
		{
			title: 'עמלות',
			icon: commissionsIcon,
			path: '/commissions',
			permission: [10],
		},
	];

	return (
		<Nav hamburger={hamburger} showMenu={userContext.menuOpen}>
			{hamburger && (
				<X
					style={{
						position: 'absolute',
						top: '2vmin',
						right: '2vmin',
						cursor: 'pointer',
					}}
					onClick={() => {
						userContext.setMenuOpen(!userContext.menuOpen);
					}}
				/>
			)}
			<div className='logo-container'>
				<img src={logo} alt='' />
				<div className='text'>ר.א צ'ק אין - פתרונות לסוכן ביטוח</div>
			</div>
			{NAV_LINKS.map((link, index) => {
				if (link.permission.includes(userContext.user.permission))
					return (
						<Link
							key={index}
							to={link.path}
							className={
								link.path !== '/'
									? location.pathname.includes(link.path)
										? 'active'
										: null
									: location.pathname === link.path
									? 'active'
									: null
							}
						>
							<link.icon />
							<div className='link_title'>{link.title}</div>
						</Link>
					);
			})}
		</Nav>
	);
};

export default NavBar;
