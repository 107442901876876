import styled, { css } from 'styled-components';
// import {GlobalStyle} from './general';

export const Actions = styled.div`
	position:absolute;
	z-index:1;
	background-color: var(--c-neutral-100);
	width:21%;
	height:calc(100% - var(--header-height));
	top: var(--header-height);
	left:0;
	padding-top:1em;
	transform:translateX(-100%);
	transition:transform 0.25s ease;
	color:var(--c-main-700);
	svg:first-of-type{
		align-self:baseline;
		margin-inline-start:1.5em;
		cursor:pointer;
	}
	&>*{
	margin-inline:auto;
	}
	.msg-title{
		width:87%;
		font-weight:700;
		font-size:var(--fz-accent);
		margin-bottom:-1vmin;
	}
	.inner{
		width:100% !important;
		height:85%;
		padding-bottom:2vmin;
		overflow-y:auto;
		&>*{
			margin-inline:auto;
			}	
			/* width */
		&::-webkit-scrollbar {
		width: 1vmin;
		}
	
		/* Track */
		&::-webkit-scrollbar-track {
		background: #f1f1f1;
		}
	
		/* Handle */
		&::-webkit-scrollbar-thumb {
		background: var(--c-main-500);
		border-radius:100vw;
		}
	
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
		background: var(--c-main-600);
		}
	}

	// @media only screen and (max-width:1600) {
	// 	width:min(450px,95%);
	// 	margin-inline:auto;
	// 	text-align:center;
	//   }
  }
`;

export const TitleCard = styled.div`
margin-block:1.2vmin 3vmin;
  width:80%;
  text-align:center;
  font-size:var(--fz-sub-title);
  font-weight:700;
  color:var(--c-main-700);
  padding-bottom:1em;
  border-bottom: 1px solid var(--c-main-400);  
}`;

export const ActionsContainer = styled.div`
	margin-block:2.5vmin;
	width:88%;
	background-color:var(--c-neutral-200);
	border-radius: var(--border-radius);
	border:1px solid var(--c-main-100);
	box-shadow:var(--box-shadow-200);
	padding-block: 0.5em;
	display:flex;
	flex-direction:column;
	align-items:center;
	font-size:var(--fz-accent);
	font-weight:700;
	gap:0.5em;
	&.short{
		height:35%;
	}
	.single-tab{
		padding: 0.3em 1em;
		font-weight:400;
	
		&:first-of-type{
			padding-inline:1em !important;
		}
	
	}

	
	textarea{
		height: 100%;
		width: 100%;
		padding-inline: 2vmin;
		background: transparent;
		border: unset;
		resize: none;
		outline: unset;
		font-weight:400;
		/* width */
		&::-webkit-scrollbar {
		width: 1vmin;
		}
	
		/* Track */
		&::-webkit-scrollbar-track {
		background: #f1f1f1;
		}
	
		/* Handle */
		&::-webkit-scrollbar-thumb {
		background: var(--c-main-500);
		border-radius:100vw;
		}
	
		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
		background: var(--c-main-600);
		}
	}

	
	
}`;
export const Cancel = styled.div`
	margin-top:auto;
	width:100%;
	height:15%;
	background-color:var(--c-neutral-200);
	display:flex;
	align-items:center;
	justify-content:center;
	position: absolute;
    bottom: 0;
	path{
		fill:var(--c-main-700);
	}
}`;

export const Status = styled.div`
width:87%;
display:flex;
gap:1vmin;
align-items:center;
justify-content:center;
font-size: var(--fz-regular);
	font-weight: 700;

span{
	width: fit-content;
	background-color: var(--c-error);
	padding: 0.5em 2em;
	
	color: var(--c-neutral-700);
}
&.active{
	span{
		background-color: var(--c-success);
	}
}

${({ status }) =>
	status &&
	css`
		span{
			background-color:${status};
		}
}`}
}`;
