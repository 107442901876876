import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { ReactComponent as ClaimsIcon } from '../../assets/images/claimsBtn.svg';
import { ReactComponent as PaymentsIcon } from '../../assets/images/paymentsBtn.svg';
import { ReactComponent as SalesIcon } from '../../assets/images/salesBtn.svg';
import { AlertContext } from '../../contexts/alertContext';
import { DataContext } from '../../contexts/dataContext';
import { UserContext } from '../../contexts/userContext';
import { ActionsContainer } from '../../styles/actions';
import { printReport, produceICountInvoice } from '../../utils/api';
import DateInput from '../Forms/DateInput';
import Button from '../buttons/Button';
import TabSlider from '../buttons/TabSlider';
import ExportExcel from '../reports/ExportExcel';

const ReportsBox = ({ setModal }) => {
	const dataContext = useContext(DataContext);
	const userContext = useContext(UserContext);
	const alertContext = useContext(AlertContext);
	const location = useLocation();
	const [title, setTitle] = useState('דוחות');
	const [startDate, setStartDate] = useState(new Date('01/01/2010'));
	const [endDate, setEndDate] = useState(new Date());
	const [dateRange, setDateRange] = useState('day');

	const navigate = useNavigate();

	useEffect(() => {
		switch (location.pathname) {
			case '/':
				setTitle('נתוני לקוח');
				break;
			case '/agents':
				setTitle('דוחות');
				break;
			case '/subscriptions':
				setTitle('דוחות');
				break;
			default:
				break;
		}
	}, [location.pathname]);

	const dateOptions = (index) => {
		switch (index) {
			case 0:
				setDateRange('day');
				handleStartDate('day');
				break;
			case 1:
				setDateRange('month');
				handleStartDate('month');
				break;
			case 2:
				setDateRange('unlimited');
				handleStartDate('unlimited');
				break;
			default:
				break;
		}
	};

	const exportReportHandler = async (e, report) => {
		switch (report) {
			case 'commissions':
				break;

			default:
				if (location.pathname !== '/agents') return;

				let res = await printReport(
					report,
					dataContext.selected?.id,
					startDate,
					endDate,
				);

				if (!res.success)
					return alertContext.handleAlert(false, res.message);
				break;
		}
	};

	const dateChangeHandler = (date, start) => {
		start ? setStartDate(date) : setEndDate(date);
		if (dateRange !== 'month') return;
		let tempDate = new Date(Number(date));
		let days = start ? 30 : -30;
		tempDate.setDate(date.getDate() + days);
		!start ? setStartDate(tempDate) : setEndDate(tempDate);
	};

	const handleStartDate = (date) => {
		switch (date) {
			case 'day':
				setStartDate(new Date());
				break;
			case 'month':
				let tempDate = new Date();
				tempDate.setDate(new Date().getDate() - 30);
				setStartDate(tempDate);
				setEndDate(new Date());
				break;
			case 'unlimited':
				setStartDate(new Date());
				setEndDate(new Date());
				break;
			default:
				break;
		}
	};

	const createSubReport = async (recipient) => {
		//open modal with report
		setModal(recipient);
	};

	const createInvoice = async () => {
		const response = await produceICountInvoice({
			sub: dataContext.selected.id,
			// payment: 113,
		});

		if (!response.success)
			return alertContext.handleAlert(false, response.message);
		window.open(response.data, '_blank');
	};

	return (
		<ActionsContainer>
			{title}
			{location.pathname == '/subscriptions' ? (
				<>
					<Button
						clickHandler={(e) => createSubReport('client')}
						border={true}
					>
						<span>דוח מנוי</span>
					</Button>
					<Button
						clickHandler={(e) => createSubReport('agent')}
						border={true}
					>
						<span>דוח ממסרים</span>
					</Button>
					{dataContext.selected?.paymentStatus === 'שולם' && (
						<Button
							clickHandler={(e) => createInvoice()}
							border={true}
						>
							<span>הפק חשבונית</span>
						</Button>
					)}
				</>
			) : location.pathname !== '/' ? (
				<>
					<TabSlider
						tabs={['יומי', 'חודשי', 'בחירה חופשית']}
						width={25}
						onClick={dateOptions}
					></TabSlider>
					<DateInput
						label={dateRange !== 'day' ? 'מתאריך' : 'תאריך'}
						selected={startDate}
						onChange={dateChangeHandler}
						datesToFilter={dateRange === 'month' ? true : false}
						start={true}
					/>
					{dateRange !== 'day' && (
						<DateInput
							label='עד תאריך'
							selected={endDate}
							datesToFilter={false}
							onChange={dateChangeHandler}
							start={false}
						/>
					)}
					{location.pathname === '/commissions' ? (
						<Button
							clickHandler={(e) =>
								exportReportHandler(e, 'commissions')
							}
							border={true}
						>
							<span>הדפס דו"ח </span>
							<SalesIcon />
						</Button>
					) : location.pathname === '/agents' ? (
						<ExportExcel startDate={startDate} endDate={endDate} />
					) : (
						<>
							<Button
								clickHandler={(e) =>
									exportReportHandler(e, 'subscriptions')
								}
								border={true}
							>
								<span>דוח מכירות</span>
								<SalesIcon />
							</Button>
						</>
					)}
				</>
			) : (
				<>
					<Link
						to={{
							pathname: `/subscriptions/${dataContext.selected?.id}`,
						}}
					>
						<Button border={true}>
							<span>מנויים</span>
							<SalesIcon />
						</Button>
					</Link>
					<Link
						to={{
							pathname: `/claims/${dataContext.selected?.id}`,
						}}
					>
						<Button border={true}>
							<span>תביעות</span>
							<ClaimsIcon />
						</Button>
					</Link>
					<Link
						to={{
							pathname: `/payments/${dataContext.selected?.id}`,
						}}
					>
						<Button border={true}>
							<span>תשלומים</span>
							<PaymentsIcon />
						</Button>
					</Link>
				</>
			)}
		</ActionsContainer>
	);
};

export default ReportsBox;
