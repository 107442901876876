import { SubBtn } from '../../styles/btns';
import { ReactComponent as Renew } from '../../assets/images/renew.svg';
import { ReactComponent as CancelIcon } from '../../assets/images/cancel.svg';
import { ReactComponent as Freeze } from '../../assets/images/freeze.svg';
import { ReactComponent as Swap } from '../../assets/images/swap.svg';
import { DataContext } from '../../contexts/dataContext';
import { useContext } from 'react';

const SubButtons = ({ setShowWarning, setMode }) => {
	const dataContext = useContext(DataContext);
	const btnsArr = [
		{
			name: 'חידוש',
			icon: <Renew />,
		},
		{
			name: 'החלפה',
			icon: <Swap />,
		},
		{
			name: 'הקפאה',
			icon: <Freeze />,
		},
		{
			name: 'הפשרה',
			icon: <Renew />,
		},
		{
			name: 'ביטול',
			icon: <CancelIcon />,
		},
	];
	const date = new Date();

	return (
		<div className='btn-container'>
			{btnsArr.map((btn, key) => {
				if (
					btn.name === 'חידוש' &&
					new Date(dataContext?.selected?.dateEnd).getTime() >
						date.setDate(date.getDate() + 30)
				)
					return null;

				if (
					dataContext.selected?.status === 'קפוא' &&
					btn.name === 'הקפאה'
				)
					return null;
				if (
					dataContext?.selected?.status !== 'קפוא' &&
					btn.name === 'הפשרה'
				)
					return null;
				return (
					<SubBtn
						onClick={() => {
							setMode(btn.name);
							setShowWarning(true);
						}}
						key={key}
					>
						<span>{btn.name}</span>
						{btn.icon}
					</SubBtn>
				);
			})}
		</div>
	);
};

export default SubButtons;
